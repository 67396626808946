import { Box, Chip } from '@mui/material';
import { FC, useMemo } from 'react';
import { ChatMessageItem } from './ChatMessageItem';
import { IChatMessageService, IChatMessages } from 'store/hooks/services/chat';
import { useSession } from 'shared/hooks/useSession';
import { dateFormat } from 'shared/constants';

interface IChatMessageGroup {
  group: IChatMessages;
  onEditMessage: (item: IChatMessageService) => void;
}

export const ChatMessageGroup: FC<IChatMessageGroup> = ({
  group,
  onEditMessage,
}) => {
  const { user } = useSession();

  const groupByUsers = useMemo(() => {
    const mGroups: any = [];

    let prevUser: any = null;
    group.messages.forEach((item) => {
      if (prevUser == item.userId) {
        mGroups[mGroups.length - 1].messages.push(item);
      } else {
        mGroups.push({
          messages: [item],
          userId: item.userId,
          user: item.user,
        });
      }

      prevUser = item.userId;
    });

    return mGroups;
  }, [group.messages]);

  const isToday = (date: Date) => {
    const today = new Date(Date.now()).toISOString().split('T')[0];
    return date.toISOString().split('T')[0] === today;
  };

  const isYesterday = (date: Date) => {
    const yesterday = new Date(Date.now() - 86400000)
      .toISOString()
      .split('T')[0];
    return date.toISOString().split('T')[0] === yesterday;
  };

  const renderDate = () => {
    const date = new Date(group.date);

    if (isToday(date)) {
      return 'Сегодня';
    }

    if (isYesterday(date)) {
      return 'Вчера';
    }

    return dateFormat(group.date, false);
  };

  return (
    <Box className="tw-mb-8">
      <Box className="tw-flex">
        <Chip
          sx={{ fontSize: '14px' }}
          className="tw-mx-auto"
          label={renderDate()}
        />
      </Box>
      <Box className="tw-pt-8">
        {groupByUsers.map((groupItem: any) =>
          groupItem.messages.map((item: any, idx: number) => (
            <ChatMessageItem
              key={item.id}
              data={item}
              isMe={user?.info?.id === item.userId}
              onEditMessage={onEditMessage}
              isLastMessageOfUser={groupItem.messages.length === idx + 1}
            />
          ))
        )}
      </Box>
    </Box>
  );
};
