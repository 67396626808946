import { FormGroup, TextField, useMediaQuery } from '@mui/material';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { IInitialValues } from '../../schema';

interface IServiceInfoForm {
  disabled?: boolean;
  formik: FormikProps<IInitialValues>;
}

export const ServiceOrgForm: FC<IServiceInfoForm> = ({ formik }) => {
  const defaultCallback = () => {};
  const isSmallDevice = useMediaQuery('only screen and (max-width : 500px)');

  return (
    <FormGroup className="tw-mb-4">
      <div className="lg:tw-grid tw-grid-cols-2 tw-gap-4">
        <TextField
          sx={{
            marginTop: isSmallDevice ? '15px' : '0',
            width: isSmallDevice ? '100%' : '100%',
            py: 1,
          }}
          name="orgName"
          label="Название организации"
          size="small"
          value={formik.values.orgName}
          onChange={defaultCallback}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <TextField
          sx={{
            marginTop: isSmallDevice ? '15px' : '0',
            width: isSmallDevice ? '100%' : '100%',
            py: 1,
          }}
          name="orgINN"
          label="ИНН ораганизации"
          size="small"
          value={formik.values.orgINN}
          onChange={defaultCallback}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <TextField
          sx={{
            marginTop: isSmallDevice ? '15px' : '0',
            width: isSmallDevice ? '100%' : '100%',
            py: 1,
          }}
          name="orgAdress"
          label="Адрес ораганизации"
          size="small"
          value={formik.values.orgAdress}
          onChange={defaultCallback}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
      </div>
    </FormGroup>
  );
};
