import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { IChatMessageService } from 'store/hooks/services/chat';
import { ChatMessageItemFiles } from './ChatMessageItemFIles';
import { hoursFormat } from 'shared/utils/functions';
import EditIcon from '@mui/icons-material/Edit';

interface IChatMessageItem {
  isMe?: boolean;
  isLastMessageOfUser?: boolean;
  data: IChatMessageService;
  onEditMessage: (item: IChatMessageService) => void;
}

export const ChatMessageItem: FC<IChatMessageItem> = ({
  isMe,
  isLastMessageOfUser,
  data,
  onEditMessage,
}) => {
  const renderAvatar = () => {
    if (!isLastMessageOfUser) return null;

    return (
      <Tooltip title={data.user?.value || 'Unknown'}>
        <Avatar
          alt={data.user?.value ? data.user.value : 'Unknown'}
          src={data.user?.avatar ?? '/static/images/avatar/1.jpg'}
          sx={{
            width: 36,
            height: 36,
            bgcolor: isMe ? '#607D8B' : '#009688',
          }}
        />
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        '&:hover .edit-icon': {
          display: 'inline-block',
        },
      }}
      className="tw-mb-3 tw-flex"
    >
      <Box
        className={`tw-inline-flex tw-items-end tw-gap-2 tw-relative tw-max-w-[60%] ${
          isMe ? 'tw-ml-auto' : ''
        } ${isLastMessageOfUser ? '' : isMe ? 'tw-pr-[40px]' : 'tw-pl-[40px]'}`}
      >
        {!isMe && renderAvatar()}
        <Box
          className={`tw-flex tw-flex-col tw-flex-gap-4 tw-px-3 tw-py-5 tw-rounded-lg tw-bg-primaryLight tw-relative ${
            isMe ? 'tw-rounded-br-none' : 'tw-rounded-bl-none'
          }`}
        >
          <Typography variant="body1">{data.text}</Typography>
          {Boolean(data.files?.length) && (
            <ChatMessageItemFiles files={data.files} />
          )}
          <Typography
            className={`tw-absolute tw-bottom-1 tw-right-2`}
            variant="caption"
          >
            {hoursFormat(data.createAt)}
          </Typography>
          <EditIcon
            onClick={() => onEditMessage(data)}
            sx={{ display: 'none' }}
            className={`edit-icon tw-absolute tw-top-1 ${
              isMe ? '-tw-left-8' : '-tw-right-8'
            } tw-cursor-pointer`}
          />
        </Box>
        {isMe && renderAvatar()}
      </Box>
    </Box>
  );
};
