import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const headerStyles: CSSProperties = {
  height: '12rem',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  position: 'relative',
  // boxShadow: '0px 6px 12px 0px rgba(28, 39, 49, 0.03)',
};

export const supportStyles: CSSProperties = {
  // height: '50px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
}

export const mainStyles: CSSProperties = {
  height: '100%',
  flex: 1,
  marginBottom: '100px',
};

export const iconSx: SxProps = {
  position: 'absolute',
  top: '50%',
  right: '4rem',
  transform: 'translateY(-50%)',
};

export const contentSx: SxProps = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F7F7FC',
};
