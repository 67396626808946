import exp from 'constants';
import { api } from 'store/api';
import { IUser } from '../userprofile';

export interface IUserWithAvatar extends ValueId {
  avatar?: string;
}

export interface IChatMessageService {
  id?: number;
  userId?: number;
  user?: {
    id: number;
    value: string;
    avatar: Nullable<string>;
  };
  sign64?: string;
  text: string;
  parentId?: number;
  parentName?: string;
  parentShortMessage?: string;
  createAt: string;
  files?: string[];
  showAcceptButton?: boolean;
}

export interface IChatMessages {
  date: string;
  messages: IChatMessageService[];
}

export interface IChatOpenRequest {
  id: number;
  docType: number;
  clientChat?: boolean;
  timestamp?: string;
}

export interface IChatResponse {
  id: number;
  state: number;
  canSendMessage: boolean;
  files: string[];
  messages: IChatMessages[];
  users: IUserWithAvatar[];
}

export interface IChatCloseRequest {
  docId: number;
  chatId: number;
  messageId: number;
}

export interface IChatSendRequest {
  chatId: number;
  parentId?: number;
  text?: string;
  files?: string[];
}

export interface IChatSendResponse {
  messageId: number;
  sended: boolean;
  message: string;
}

export interface IChatNewMessageRequest {
  chatId: number;
  lastId: number;
}

export interface IChatNewMessageResponse {
  chatId: number;
  state: number;
  canSendMessage: boolean;
  messages: IChatMessageService[];
}

export interface IChatEditMessageRequest extends IChatSendRequest {
  id: number;
}

// Chat >>>

export const chatApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // chat
    openChat: builder.mutation<IChatResponse, IChatOpenRequest>({
      query: (data) => ({
        url: '/api/Chat/create',
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
    closeChat: builder.mutation<IChatResponse, IChatCloseRequest>({
      query: (data) => ({
        url: '/api/Chat/close',
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
    getChatById: builder.mutation<IChatResponse, number>({
      query: (id) => ({
        url: '/api/Chat/get/' + id,
        method: 'GET',
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
    sendMessage: builder.mutation<IChatSendResponse, IChatSendRequest>({
      query: (data) => ({
        url: '/api/Chat/sendmessage',
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
    editMessage: builder.mutation<IChatSendResponse, IChatEditMessageRequest>({
      query: (data) => ({
        url: '/api/Chat/editMessage',
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
    newMessage: builder.mutation<
      IChatNewMessageResponse,
      IChatNewMessageRequest
    >({
      query: (data) => ({
        url: '/api/Chat/newmessage',
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['IncomingV3Chat'],
    }),
  }),
});

export const {
  // Chat
  useOpenChatMutation,
  useCloseChatMutation,
  useGetChatByIdMutation,
  useSendMessageMutation,
  useEditMessageMutation,
  useNewMessageMutation,
} = chatApi;
